import { ReactNode } from 'react';
import { Button, ButtonProps, Popconfirm } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Props extends ButtonProps {
  onConfirm: () => void;
  children?: ReactNode;
  confirmTitle?: string;
  placement?: TooltipPlacement;
  disabled?: boolean;
}
const ConfirmButton = ({
  onConfirm,
  children,
  confirmTitle,
  placement,
  disabled,
  ...props
}: Props) => (
  <Popconfirm
    title={confirmTitle}
    onConfirm={onConfirm}
    placement={placement}
    disabled={disabled}>
    <Button {...props} disabled={disabled}>
      {children}
    </Button>
  </Popconfirm>
);

ConfirmButton.defaultProps = {
  children: 'Save',
  confirmTitle: 'Are you sure to submit',
  placement: 'topRight',
  disabled: false,
};

export default ConfirmButton;
