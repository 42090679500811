/* eslint-disable */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { PlayerDetails } from '../types';

import {
  getPlayerDetailsThunk,
  RestoreBetHistoryThunk,
  updatePlayerDetailsThunk,
} from './playerDetailsThunk';
import { ICreateNote } from './playerDetails.types';

export interface PlayerState {
  data: PlayerDetails | null;
  loading: boolean;
  error: string | null;
}

const initialState: PlayerState = {
  data: null,
  loading: false,
  error: null,
};

const playerDetailsSlice = createSlice({
  name: 'playerDetails',
  initialState,
  reducers: {
    deleteNote: (state, action: PayloadAction<number>) => {
      if (state.data) {
        state.data.notes = state.data.notes.filter(
          note => note.id !== action.payload,
        );
      }
    },
    addNote: (state, action: PayloadAction<any>) => {
      if (state.data) {
        state.data.notes.unshift(action.payload);
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getPlayerDetailsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlayerDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getPlayerDetailsThunk.rejected, state => {
        state.loading = false;
        state.error = 'Failed to fetch players';
      })
      .addCase(updatePlayerDetailsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePlayerDetailsThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        toast.success('Player details updated successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(updatePlayerDetailsThunk.rejected, (state, action) => {
        state.loading = false;
        if (action.payload && typeof action.payload === 'object') {
          const errorPayload = action.payload as any;
          state.error = errorPayload;
          toast.error(errorPayload.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .addCase(RestoreBetHistoryThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(RestoreBetHistoryThunk.fulfilled, (state, action) => {
        state.loading = false;
        toast.success('Restored Successfully', {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .addCase(RestoreBetHistoryThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = 'Failed to restore bet history';
        toast.error(state.error, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  },
});

export const { deleteNote, addNote } = playerDetailsSlice.actions;

export default playerDetailsSlice.reducer;
