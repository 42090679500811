import { Col, Form, Input, Row, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import {
  RestoreBetHistoryThunk,
  updatePlayerDetailsThunk,
} from '../../../../../../redux/reducers/players/playerDetails/playerDetailsThunk';
import { useAppDispatch } from '../../../../../../redux/hooks/redux.hooks';
import ConfirmButton from '../../../../../shared/ConfirmButton';
import { parseNumericValuesForInputs } from '../../../../../../helpers/utils';
import { RootState } from '../../../../../../redux/store.types';
import { PlayerState } from '../../../../../../redux/reducers/players/playerDetails/playerDetailsSlice';

import { SettingsPlayerDetailsData } from './Helpers/Settings.types';

const SettingsTab = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { data: playerData }: PlayerState = useSelector(
    (state: RootState) => state.playerDetails,
  );
  const [changedValues, setChangedValues] = useState<
    Partial<SettingsPlayerDetailsData>
  >({});

  const onValuesChange = (changed: Partial<SettingsPlayerDetailsData>) => {
    setChangedValues((prev: Partial<SettingsPlayerDetailsData>) => {
      if (changed.balance_state) {
        return {
          ...prev,
          balance_state: {
            ...prev.balance_state,
            ...changed.balance_state,
          },
        };
      }

      return {
        ...prev,
        ...changed,
      };
    });
  };

  const handleRestoreBetHistory = () => {
    dispatch(RestoreBetHistoryThunk(playerData?.id));
  };

  const handleRecoverDeletedAcc = () => {
    dispatch(
      updatePlayerDetailsThunk({
        playerId: playerData?.id,
        data: {
          restore_account: true,
        },
      }),
    );
  };

  const handleAutoApprove = () => {
    dispatch(
      updatePlayerDetailsThunk({
        playerId: playerData?.id,
        data: {
          aa_enabled: !playerData?.aa_enabled,
        },
      }),
    );
  };

  const onFinish = () => {
    const valuesForUpdate = Object.keys(
      changedValues,
    ).reduce<SettingsPlayerDetailsData>((acc, elem) => {
      if (elem === 'password' && changedValues.password) {
        acc.password = changedValues.password;
      }
      if (elem === 'balance_state') {
        const { canceled, unused, used, limit } =
          changedValues.balance_state || {};
        acc.balance_state = {
          canceled:
            (canceled
              ? Number(canceled)
              : playerData?.balance_state?.canceled) || 0,
          unused:
            (unused ? Number(unused) : playerData?.balance_state?.unused) || 0,
          used: (used ? Number(used) : playerData?.balance_state?.used) || 0,
          limit:
            (limit ? Number(limit) : playerData?.balance_state?.limit) || 0,
        };
      }
      if (elem === 'bet_limit') {
        acc.bet_limit = Number(changedValues.bet_limit) || 0;
      }
      if (elem === 'live_delay') {
        acc.live_delay = Number(changedValues.live_delay) || 0;
      }
      return acc;
    }, {} as SettingsPlayerDetailsData);

    if (Object.keys(valuesForUpdate).length) {
      dispatch(
        updatePlayerDetailsThunk({
          playerId: playerData?.id,
          data: valuesForUpdate,
        }),
      );
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onValuesChange={onValuesChange}
      layout='vertical'
      initialValues={{
        balance_state: {
          limit: parseNumericValuesForInputs(playerData?.balance_state?.limit),
          used: parseNumericValuesForInputs(playerData?.balance_state?.used),
          unused: parseNumericValuesForInputs(
            playerData?.balance_state?.unused,
          ),
          canceled: parseNumericValuesForInputs(
            playerData?.balance_state?.canceled,
          ),
        },
        live_delay: playerData?.live_delay,
        bet_limit: parseNumericValuesForInputs(playerData?.bet_limit),
      }}>
      <Row gutter={[40, 24]}>
        <Col span={4}>
          <Form.Item label='AWA' name={['balance_state', 'limit']}>
            <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Used' name={['balance_state', 'used']}>
            <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Unused' name={['balance_state', 'unused']}>
            <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Canceled' name={['balance_state', 'canceled']}>
            <Input type='number' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 24]}>
        <Col span={4}>
          <Form.Item label='Live Delay' name='live_delay'>
            <Input type='number' />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label='Bet Limit' name='bet_limit'>
            <Input type='number' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[100, 24]}>
        <Col
          style={{
            display: 'flex',
            alignSelf: 'center',
          }}
          span={6}>
          <Form.Item
            label='Password Change'
            name='password'
            rules={[{ min: 5, message: 'Min 5 symbols' }]}>
            <Input type='text' />
          </Form.Item>
        </Col>
        <Col
          style={{
            display: 'flex',
            alignSelf: 'center',
          }}>
          <ConfirmButton type='primary' onConfirm={() => form.submit()} />
        </Col>
      </Row>

      <Row gutter={[40, 24]} style={{ marginTop: 24 }}>
        <Col>
          <ConfirmButton type='primary' onConfirm={handleRestoreBetHistory}>
            Restore Bet History
          </ConfirmButton>
        </Col>
        <Col>
          <ConfirmButton
            type='primary'
            disabled={!playerData?.deleted}
            onConfirm={handleRecoverDeletedAcc}>
            Recover Deleted Acc
          </ConfirmButton>
        </Col>
        <Col style={{ alignSelf: 'center' }}>
          <div style={{ display: 'flex', gap: 10 }}>
            <span>Auto Approve</span>
            <Switch
              checked={playerData?.aa_enabled}
              onChange={handleAutoApprove}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default SettingsTab;
