import { Checkbox, Col } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { FC } from 'react';

type OptionType = { label: string; value: string };

const isOptionTypeArray = (
  options: OptionType[] | string[],
): options is OptionType[] => {
  return (
    options.length > 0 &&
    typeof options[0] === 'object' &&
    'label' in options[0] &&
    'value' in options[0]
  );
};

type Proptypes = {
  name: string;
  options: OptionType[] | string[];
  value: CheckboxValueType[];
  onAllCheck: (name: string, values: CheckboxValueType[]) => void;
  onFilterChange: (name: string, values: CheckboxValueType[]) => void;
  span: number;
};

const CheckboxGroup: FC<Proptypes> = ({
  name,
  options,
  value,
  onAllCheck,
  onFilterChange,
  span,
}) => {
  const isAllCheck = options.length === value.length;
  const optionValues = isOptionTypeArray(options)
    ? options.map((option: OptionType) => option.value)
    : options;

  return (
    <>
      <Col span={span} style={{ whiteSpace: 'nowrap', marginRight: '15px' }}>
        {name}:
      </Col>
      <Checkbox
        checked={isAllCheck}
        onChange={() => onAllCheck(name, isAllCheck ? [] : optionValues)}>
        ALL
      </Checkbox>

      <Col>
        <Checkbox.Group
          value={value}
          options={options}
          onChange={values => onFilterChange(name, values)}
          style={{ flexWrap: 'wrap' }}
        />
      </Col>
    </>
  );
};

export default CheckboxGroup;
